<template>
  <div class="container" id="segmentation_relations_edit">
    <h1 v-html="$t('segmentation_relations_edit_title')"></h1>
    <v-form ref="form" @submit.prevent="onSubmit()">
      <!-- Ablieferung durch -->
      <h2 v-html="$t('segmentation_relations_delivery_to')"></h2>
      <v-row>
        <v-col cols="3" sm="6" md="3">
          <v-text-field
            id="dairyOutIdent"
            v-model="segmentationRelation.dairyOut.ident"
            disabled
            type="number"
            :label="$t('segmentation_relations_dairy_ident_in')"
          />
        </v-col>
      </v-row>

      <!-- Zukauf durch -->
      <h2 v-html="$t('segmentation_relations_additional_purchase')"></h2>
      <v-row>
        <v-col cols="3" sm="6" md="3">
          <v-text-field
            id="dairyInIdent"
            v-model="segmentationRelation.dairyIn.ident"
            disabled
            type="number"
            :label="$t('segmentation_relations_dairy_ident_out')"
          />
        </v-col>
      </v-row>

      <!-- von -->
      <h2 v-html="$t('segmentation_relations_from')"></h2>
      <v-row>
        <v-col cols="3" sm="6" md="3">
          <DbmDatePicker v-model="segmentationRelation.validFrom" :dateLabel="$t('segmentation_relations_from')" />
        </v-col>
      </v-row>

      <!-- bis -->
      <h2 v-html="$t('segmentation_relations_until')"></h2>
      <v-row>
        <v-col cols="3" sm="6" md="3">
          <div class="dateBox">
            <DbmDatePicker v-model="segmentationRelation.validUntil" :dateLabel="$t('segmentation_relations_until')" />
          </div>
        </v-col>
      </v-row>

      <!-- Fütterung -->
      <h2 v-html="$t('search_segmentation_relations_feeding')"></h2>
      <v-row>
        <v-col cols="3" sm="6" md="3">
          <v-select
            id="feeding"
            v-model="segmentationRelation.feeding"
            :label="$t('search_segmentation_relations_feeding')"
            disabled
            :items="feedings"
            item-title="text"
            item-value="value"
            clearable
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="item card-spacing" sm="12" md="6" lg="6">
          <rdhComponent
            :show="$privileges.has({ path: '/masterData', permission: 'showRemarksDocumentsHistory' })"
            :history_show="true"
            :history_url="'/segmentationRelations/' + $route.params.id"
            ref="rdh"
          ></rdhComponent>
        </v-col>
      </v-row>

      <v-row>
        <div class="aligned-buttons">
          <v-btn color="primary" class="shadow-none" type="submit"><span v-html="$t('segmentation_relations_save')" /></v-btn>
          <v-btn color="secondary" class="shadow-none" @click="deleteRelation()"><span v-html="$t('segmentation_relations_delete')" /></v-btn>
          <v-btn color="secondary" class="shadow-none" @click="goBack()"><span v-html="$t('back')" /></v-btn>
        </div>
      </v-row>
    </v-form>
  </div>
</template>
<script lang="ts">
import { apiURL, emitter } from '@/main'
import { showError } from '@/services/axios'
import _ from 'lodash'
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import rdhComponent from '@/components/rdh/rdhComponent'
import { authService } from '@/services/auth.service'

const segmentationRelationMinimal = {
  dairyIn: {
    id: null,
    ident: null
  },
  dairyOut: {
    id: null,
    ident: null
  },
  validFrom: '2021-01-01',
  validUntil: '2021-01-01',
  feeding: 0
}

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'segmentationRelations_edit',
  props: {},
  components: {
    DbmDatePicker: DbmDatePicker,
    rdhComponent: rdhComponent
  },
  data() {
    return {
      feedings: [
        {
          value: 'NO_SILAGE',
          text: this.$t('segmentation_relations_no_silage')
        },
        { value: 'SILAGE', text: this.$t('segmentation_relations_silage') }
      ],
      segmentationRelation: segmentationRelationMinimal
    }
  },
  methods: {
    onValidFrom(e) {
      this.segmentationRelation.validFrom = e
    },
    async loadSegmentation() {
      try {
        const result = await this.axios.get(apiURL + '/segmentationRelations/' + this.$route.params.id, { headers: { 'Content-Type': 'application/json' } })
        this.segmentationRelation = result.data
        return result.data
      } catch (e) {
        showError(e)
      }
    },
    async onSubmit() {
      this.$refs.form.validate()
      if ((await this.$refs.form.validate()).valid) {
        try {
          let reducedSegmentationRelation = _.omit(this.segmentationRelation, ['dairyIn', 'dairyOut'])
          await this.axios.patch(
            apiURL + '/segmentationRelations/' + this.$route.params.id,
            {
              ...reducedSegmentationRelation
            },
            { headers: { 'Content-Type': 'application/json' } }
          )
          // this.loadSegmentation()
          emitter.emit('toastSuccess', {
            message: this.$t('toast_save_success_message')
          })
        } catch (e) {
          showError(e)
        }
      }
    },
    goBack() {
      this.$router.push({ name: 'segmentationRelations_read' }).catch(() => {
        /* duplicated route */
      })
    },
    async deleteRelation() {
      try {
        await this.axios.delete(apiURL + '/segmentationRelations/' + this.$route.params.id, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authService.loadedUser.access_token
          }
        })

        emitter.emit('toastSuccess', {
          message: this.$t('thedatahasbeendeleted')
        })
        this.$router.push({ name: 'segmentationRelations_read' }).catch(() => {
          /* duplicated route */
        })
      } catch (e) {
        showError(e)
      }
    }
  },
  mounted: function () {
    this.loadSegmentation()
    emitter.emit('loadHistory')
  }
})
</script>
<style lang="scss" scoped>
.dateBox {
  margin: 1rem;
}
</style>
